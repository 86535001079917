import {useRhinoStore} from "../store";
import {Input, Stack, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import Grid2 from "@mui/material/Unstable_Grid2";
import {useState} from "react";
import {useStopwatch} from "react-timer-hook";
import axios from "axios";

export const GuessForm = () => {

    const dispatch = useRhinoStore(state=>state.dispatch);

    const [loading, setLoading] = useState(false);

    const [guessArray, setGuessArray] = useState({
            guess_1: "",
            guess_2: "",
            guess_3: "",
            guess_4: "",
            guess_5: ""
        }
    )

    const {
        totalSeconds,
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        reset,
    } = useStopwatch({ autoStart: true });

    const handleChange = async (event, fieldNum) => {
        setGuessArray(prevState=>({
            ...guessArray,
            [fieldNum]: event.target.value
        }))
    }

    const handleSubmit = async (event) => {

        event.preventDefault()

        console.log("GUESS ARRAY NEW:")
        console.log(guessArray )

        setLoading(true);

        // send to server
        // expected response:
        // {
        //     "tracks": {
        //         "guess_1": "Helter Skelter",
        //         "guess_2": "Drive My Car",
        //         "guess_3": "While My Guitar Gently Weeps",
        //         "guess_4": "Piggies",
        //         "guess_5": "Ticket to Ride"
        // },
        //     "score": 0
        // }

        let data = JSON.stringify({
            "artist": "b10bbbfc-cf9e-42e0-be17-e2c3e1d2600d",
            "total_seconds": totalSeconds,
            "payload": guessArray
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            mode: 'no-cors',
            url: 'https://damp-springs-75950-f79aec4577a5.herokuapp.com/submit-answers',
            headers: {
                'Content-Type': 'application/json'
            },
            data : data
        };

        try{
            const scoreData = await axios.request(config);

            await dispatch({
                action: "SETSCORE",
                payload: {
                    score: scoreData.data.score
                }
            })

            await dispatch({
                action: "SETSONGS",
                payload: {
                    songs: scoreData.data.tracks
                }
            })

            await dispatch({
                action: "CHANGESCENE",
                payload: {
                    new_scene: "SCORESCREEN"
                }
            })

            console.log(guessArray)
            await  dispatch({
                action: "SETGUESSES",
                payload: {
                    data: guessArray
                }
            })

        }
        catch(e){

        }

        setLoading(false)
    }

    return (
        <Grid2 alignContent={"center"} marginLeft={5} marginRight={5} marginTop={5}>
            <img src={"https://upload.wikimedia.org/wikipedia/en/5/50/Sgt._Pepper%27s_Lonely_Hearts_Club_Band.jpg"} alt={"The Beatles"}/>
            {!loading ?
                <>
                    <Grid2 xs={12} marginTop={5}>
                        <Typography fontSize={"large"}>
                            Guess the Top Five Most Popular Songs by The Beatles!
                        </Typography>
                        <Typography fontSize={"small"}>
                            Don't know five songs? Don't worry, just submit anyways!
                        </Typography>
                    </Grid2>
                    <Grid2 xs={12}>
                        <form onSubmit={handleSubmit}>
                            <Grid2 container justifyContent={"center"} marginTop={5}>
                                <Stack spacing={5}>
                                    <Input color={"warning"} placeholder={"Enter the #1 Song"} onChange={e=>handleChange(e, 'guess_1')} />
                                    <Input color={"warning"} placeholder={"Enter the #2 Song"} onChange={e=>handleChange(e, 'guess_2')}/>
                                    <Input color={"warning"} placeholder={"Enter the #3 Song"} onChange={e=>handleChange(e, 'guess_3')}/>
                                    <Input color={"warning"} placeholder={"Enter the #4 Song"} onChange={e=>handleChange(e, 'guess_4')}/>
                                    <Input color={"warning"} placeholder={"Enter the #5 Song"} onChange={e=>handleChange(e, 'guess_5')}/>
                                    <Button className={"playButton"} type={"submit"} variant={"solid"}>Submit Guesses</Button>
                                </Stack>
                            </Grid2>
                        </form>
                    </Grid2>
                </>
            :
                <Grid2 alignContent={"center"} marginTop={10}>
                    <Typography fontSize={"medium"}>
                        Loading...
                    </Typography>
                </Grid2>}
        </Grid2>
    )
}