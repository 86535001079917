import Grid2 from "@mui/material/Unstable_Grid2";
import {Divider, List, ListItem, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {useRhinoStore} from "../store";

export const SubmitGuesses = () => {

    const dispatch = useRhinoStore(state=>state.dispatch)

    const handleButton = async () => {
        dispatch({
            action: "CHANGESCENE",
            payload: {
                new_scene: "FORMSCREEN"
            }
        })
    }

    return (
        <Grid2 marginTop={10} alignContent={"center"} marginLeft={5} marginRight={5}>
            <Grid2>
                <Typography fontSize={"large"} fontSize={"xxx-large"}>
                    How to Play:
                </Typography>
            </Grid2>
            <Grid2>
                <Typography>
                    On the next screen, a timer will start and you will be presented with 5 input boxes, of which you are to insert your guesses into.
                </Typography>
                <Typography>
                    You are then graded on a very advanced algorithm i created that is something like time spent answering * your correctness of the answer.
                </Typography>

            </Grid2>
            <Grid2 xs={12} alignContent={"center"} marginTop={10}>
                <Typography fontSize={"xxx-large"}>
                    Rules & Tips
                </Typography>
            </Grid2>
            <Grid2 xs={12} alignContent={"center"}>
                <Typography>
                    To get the best score, you want to guess them in the correct order!
                </Typography>

                <Typography>
                    The longer you take the more of a time penalty you receive!
                </Typography>

                <Typography>
                    You will receive points even for trying!
                </Typography>

                <Typography>
                    Have Fun! :)
                </Typography>
            </Grid2>
            <Grid2 marginTop={10}>
                <Button className={"playButton"} onClick={handleButton} variant={"solid"}>
                    Are you ready to begin? The clock will start!
                </Button>
            </Grid2>
        </Grid2>
    )
}