import './App.css';
import {SceneChanger} from "./components/SceneChanger";
import Cookies from "universal-cookie";
import {Box} from "@mui/material";


function App() {

    const cookies = new Cookies(null, { path: "/" })

    console.log("TODAY: ")
    const today = new Date();
    console.log(today)

    const lastDay = new Date(cookies.get('timestamp'));

    console.log("Last Stamp:")
    console.log(lastDay)

    // if(today.getDay() !== lastDay.getDay()){

        // new day starts
        // cookies.set("user_here_before", false);
        // cookies.set("timestamp", new Date())
        //
        // console.log("User here before")
        // console.log(cookies.get("user_here_before"))
        //
        // console.log("Timestamp:")
        // console.log(cookies.get("timestamp"))

        // have to remember to set the user_here_before cookie at the score screen

        return (
            <div className="App">
                <div className="area">
                    <ul className="circles">
                        <Box
                            sx={{
                                mb: 2,
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                                overflow: "hidden",
                                overflowY: "scroll",
                                // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
                            }}
                        >
                            <SceneChanger/>
                        </Box>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
            </div>
        );
    // }
    // else{
    //     return(
    //         <>
    //             Thanks for playing!
    //         </>
    //     )
    // }

}

export default App;
