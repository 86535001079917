import { create } from 'zustand';


const reducer = (state, { action, payload }) => {
    switch (action) {
        case "CHANGESCENE":
            return { current_scene: payload.new_scene}
        case "SETSCORE":
            if (payload.score >= state.high_score){
                return {
                    score: payload.score,
                    high_score: payload.score
                }
            }
            else{
                return{ score: payload.score }
            }
        case "SETSONGS":
            return { correct_songs: payload.songs }
        case "SETGUESSES":
            return { guesses: payload.data }
    }
}

export const useRhinoStore = create((set) => ({
    current_scene: "HOME",
    high_score: 0,
    score: 0,
    correct_songs: [],
    guesses: {},
    dispatch: (args) => set((state) => reducer(state, args))
}))