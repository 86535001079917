import {useRhinoStore} from "../store";
import Grid2 from "@mui/material/Unstable_Grid2";
import {Typography, List, Card, CardContent, Container, Link, Chip} from "@mui/material";
import Button from "@mui/material/Button";
import Cookies from "universal-cookie";

export const ScoreScreen = () => {

    const dispatch = useRhinoStore(state=>state.dispatch)

    const score = useRhinoStore(state=>state.score)

    const songs = useRhinoStore(state=>state.correct_songs)

    const guessesList = useRhinoStore(state=>state.guesses)

    const cookies = new Cookies(null, { path: "/" })

    const oldScore = cookies.get('score')
    // set score
    cookies.set("score", score);

    console.log(useRhinoStore());

    return (
      <Grid2 container alignContent={"center"} rowGap={2} marginLeft={5} marginRight={5}>
            <Grid2 container sx={{ width: '100%' }} >
            <Grid2 xs={12} marginBottom={5}>
                <Typography fontSize={"xxx-large"} align={"center"} fontWeight={500}>
                    Your Score:
                </Typography>
                <Typography fontSize={"xx-large"} align={"center"} fontWeight={800}>
                    {oldScore ? oldScore : score}
                </Typography>
            </Grid2>
            <Grid2 xs={12}>
                <Grid2 xs={12} sm={12} md={12} lg={6} xl={4}  sx={{ width: '100%' }}>
                    <Typography fontSize={"x-large"} align={"center"} fontWeight={500}>
                        Here are the top 5 most popular songs by The Beatles:
                    </Typography>
                </Grid2>
                <Grid2 alignContent={"center"} sx={{width: "100%"}} marginTop={5}>
                    {
                        Object.entries(songs).map(([key,song])=>{
                            return(
                                <Grid2 align={"center"} sx={{ width: '100%' }} marginBottom={3}>
                                    <Typography fontSize="x-large" fontWeight="bold" key={key}>
                                        {song}
                                    </Typography>
                                </Grid2>
                            )
                        })
                    }
                </Grid2>
            </Grid2>
            <Grid2 xs={12} marginBottom={5}>
                <Typography fontSize={"x-large"}>
                    Your guesses:
                </Typography>
                {
                    guessesList && Object.entries(guessesList).map(([key,value])=>{

                            return(
                                <Typography fontSize={"large"} key={key}>
                                    {value}
                                </Typography>
                            )

                    })
                }
            </Grid2>
            <Grid2 alignContent={"center"} xs={12}>
                <Typography>Thank you for playing! See you tomorrow for the next challenge!</Typography>
            </Grid2>
            <Grid2 alignContent={"center"} xs={12} marginTop={5}>
                <Typography color={"darkblue"} sx={{fontStyle: "italic"}}>Bug? Questions? E-mail me at <Link
                    href="#common-examples"
                    underline="none"
                    variant="outlined"
                    color="neutral"
                    sx={{ '--Link-gap': '0.5rem', pl: 1, py: 0.5, borderRadius: 'md', color: 'darkblue', bgcolor: "pink",
                        "&:hover": {
                            bgcolor: "purple",
                            color: "white"
                        }, }}
                >thomaslanenh@gmail.com</Link></Typography>
            </Grid2>
            </Grid2>
        </Grid2>
    )
}