import {useRhinoStore} from "../store";
import {Home} from "./Home";
import {SubmitGuesses} from "./SubmitGuesses";
import {GuessForm} from "./GuessForm";
import {ScoreScreen} from "./ScoreScreen";

export const SceneChanger = () => {

    const currentScene = useRhinoStore(state => state.current_scene)

    switch(currentScene){
        case "HOME":
            return <Home/>
        case "STARTGAME":
            return <SubmitGuesses/>
        case "FORMSCREEN":
            return <GuessForm/>
        case "SCORESCREEN":
            return <ScoreScreen/>
        default:
            return <Home/>
    }
}