import Grid2 from "@mui/material/Unstable_Grid2";
import {Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {useRhinoStore} from "../store";

export const Home = () => {

    const dispatch = useRhinoStore(state=>state.dispatch)

    const handleButton = async (event) => {
        dispatch({
            action: "CHANGESCENE",
            payload: {
                new_scene: "STARTGAME"
            }
        })
    }

    return (
        <Grid2 container spacing={2} sx={{ width: '100%' }} >
            <Grid2 xl={12} marginTop={10} marginLeft={5} marginRight={5}>
                <Typography level={"h1"} fontSize={"xx-large"}>

                </Typography>
                <Typography level={"h2"}>
                    Guess the Top 5 Most Popular Songs for a Artist on a music streaming site!
                </Typography>
            </Grid2>
            <Grid2 xl={12} marginLeft={5} marginRight={5} sx={{ width: '100%' }} alignContent={"center"}>
                <Typography level={"h2"} fontSize={"x-large"} >
                    Today's Artist:
                </Typography>
            </Grid2>
            <Grid2 xl={12} marginLeft={5} marginRight={5} sx={{ width: '100%' }} alignContent={"center"}>
                <img className={"mainBand"} width="50%" height={"100%"} src={"https://upload.wikimedia.org/wikipedia/commons/0/04/Paul%2C_George_%26_John.png"} alt={"The Beatles"}/>
            </Grid2>
            <Grid2 marginLeft={5} marginRight={5} alignItems={"center"} sx={{ width: '100%' }}>
                <Typography level={"h1"}>
                    The Beatles
                </Typography>
                <Button variant={"contained"} onClick={handleButton}>
                    Begin Playing!
                </Button>
            </Grid2>
        </Grid2>
    )
}